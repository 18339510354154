<template>
    <!-- Modal -->
    <div class="modal fade" id="modal_meet_up" tabindex="-1" aria-labelledby="modal_meet_upLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content modal-meet-up-content">
                <div class="modal-body">
                    <div class="head-mod-container">
                        <h3 class="head-modal-title">
                        Meetup Details
                        </h3>
                        <p><span>Borrower’s Name/Acquiring member</span> your article <span>Article Name here</span>. Please provide meet-up details below:</p>
                    </div>
                    <form class="change-pass-form">
                        <div class="mb-3">
                            <label for="datepicker" class="col-form-label">Date</label>
                            <input type="Text" placeholder="MM/DD/YYYY" class="form-control" id="datepicker">
                        </div>
                        <div class="mb-3">
                            <label for="m_time" class="col-form-label">Time</label>
                            <input type="text" placeholder="Enter time" class="form-control" id="m_time">
                        </div>
                        <div class="mb-3">
                            <label for="m_location" class="col-form-label">Location Name</label>
                            <input type="text" placeholder="Enter location name" class="form-control" id="m_location">
                        </div>
                        <div class="mb-3">
                            <label for="m_location_add" class="col-form-label">Location Address</label>
                            <input type="text" placeholder="Enter location address" class="form-control" id="m_location_add">
                        </div>
                    </form>
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-submit-change" data-bs-toggle="modal">Submit</button>
                        <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
</template>