<template>
    <!-- Notif Modal -->
    <div class="modal fade" id="modal_notif_success" tabindex="-1" aria-labelledby="modal_notif_successLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-notif_success-content">
            <div class="modal-body">
                <div class="head-mod-container">
                    <div class="msg-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114">
                        <g id="check-success" transform="translate(-190 240)">
                            <g id="Layer_44" data-name="Layer 44" transform="translate(190 -240)">
                            <path id="Path_80" data-name="Path 80" d="M59,2a57,57,0,1,0,57,57A57,57,0,0,0,59,2ZM80.147,51.647l-22.8,22.8a5.7,5.7,0,0,1-8.094,0l-11.4-11.4a5.723,5.723,0,0,1,8.094-8.094l7.353,7.41,18.753-18.81a5.723,5.723,0,0,1,8.094,8.094Z" transform="translate(-2 -2)" fill="#6c782a"/>
                            </g>
                        </g>
                        </svg>
                    </div>
                    <h3 class="head-modal-title">
                        Successful
                    </h3>
                    <p class="des-modal">
                        The article is successfully saved.
                    </p>
                </div>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-notif-ok" data-bs-dismiss="modal">Ok</button>
                </div>
            </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
</template>