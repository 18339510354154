<template>
    <section class="share-section">
        <div class="container my-container">
            <div class="share-content">
                <div class="share-wrapper-container">
                    <form class="share">
                    <div class="btn-group btn-select-container" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" v-model="form.type" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" value="Give" checked>
                        <label class="btn btn-select-give img-fluid" for="btnradio1">
                            <div class="box-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="62" viewBox="0 0 50 62">
                                <g id="give" transform="translate(-7 -1)">
                                    <g id="Group_230" data-name="Group 230">
                                    <g id="Group_229" data-name="Group 229">
                                        <path id="Path_956" data-name="Path 956" d="M52,15H44.273A7.993,7.993,0,1,0,32,5.136,7.994,7.994,0,1,0,19.727,15H12a5.006,5.006,0,0,0-5,5V58a5.006,5.006,0,0,0,5,5H52a5.006,5.006,0,0,0,5-5V20A5.006,5.006,0,0,0,52,15ZM33,11V9a6,6,0,1,1,6,6H33ZM19,9A6,6,0,0,1,31,9v6H25A6.006,6.006,0,0,1,19,9ZM55,58a3,3,0,0,1-3,3H12a3,3,0,0,1-3-3V27H55Z" fill="#656565"/>
                                        <path id="Path_957" data-name="Path 957" d="M25.581,51.908a1,1,0,0,0,.838,0A43.217,43.217,0,0,0,32,48.7a42.764,42.764,0,0,0,5.585,3.209,1,1,0,0,0,.838,0C38.974,51.652,52,45.553,52,38a7.775,7.775,0,0,0-8-8,8.818,8.818,0,0,0-6,2.607A8.818,8.818,0,0,0,32,30a8.44,8.44,0,0,0-6.035,2.582A8.76,8.76,0,0,0,20,30a7.775,7.775,0,0,0-8,8C12,45.553,25.026,51.652,25.581,51.908ZM20,32a7.028,7.028,0,0,1,4.816,2.242A8.4,8.4,0,0,0,24,38c0,3.656,3.053,6.969,6.265,9.445A43.553,43.553,0,0,1,26,49.891C22.287,48.088,14,43.009,14,38A5.785,5.785,0,0,1,20,32Z" fill="#656565"/>
                                    </g>
                                    </g>
                                </g>
                                </svg>
                            </div>
                            <h4>Give</h4>
                            <p>You are not expecting this article<br> to be returned to you.</p>
                        </label>
                        <input type="radio" v-model="form.type" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" value="Lend">
                        <label class="btn btn-select-lend" for="btnradio3">
                            <div class="box-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="59.323" height="74.5" viewBox="0 0 59.323 74.5">
                                    <g id="lend" transform="translate(-3.839 9.25)">
                                        <g id="Group_228" data-name="Group 228">
                                        <path id="Path_953" data-name="Path 953" d="M3.839,61.68V46.9h7.523v1.562c0,.007,0,.013,0,.02v11.33c0,.009,0,.017,0,.026v1.838H3.839Zm28.685,3.029a4.536,4.536,0,0,1-3.913.186L12.936,59.263V49.272h5.936a6.9,6.9,0,0,1,5.942,3.242.789.789,0,0,0,.676.382h5.975a2.5,2.5,0,0,1,2.6,2.377,2.183,2.183,0,0,1-.155.809.7.7,0,0,0-.032.076,2.61,2.61,0,0,1-2.412,1.493H22.322a.787.787,0,1,0,0,1.575h9.144a4.2,4.2,0,0,0,3.756-2.231l9.252-4.911a6.436,6.436,0,0,1,7.449,1.06Z" fill="#656565"/>
                                        <path id="Path_954" data-name="Path 954" d="M48.127,17.729a6.9,6.9,0,0,1-5.942-3.243.789.789,0,0,0-.676-.382H35.534a2.5,2.5,0,0,1-2.6-2.377,2.183,2.183,0,0,1,.158-.817.569.569,0,0,0,.025-.057,2.609,2.609,0,0,1,2.415-1.5h9.145a.787.787,0,0,0,0-1.575H35.534a4.2,4.2,0,0,0-3.755,2.23l-9.253,4.911a6.434,6.434,0,0,1-7.449-1.06l19.4-11.564a4.525,4.525,0,0,1,3.913-.186l15.674,5.63v9.992H48.127ZM63.161,20.1H55.638V18.542c0-.009.005-.016.005-.025V7.185c0-.011,0-.022-.005-.033V5.321h7.523Z" transform="translate(0 -11)" fill="#656565"/>
                                        </g>
                                        <g id="noun_Book_3886903" transform="translate(11.277 15.438)">
                                        <g id="Group_227" data-name="Group 227" transform="translate(-0.002)">
                                            <path id="Path_952" data-name="Path 952" d="M1.535,23.408a1.558,1.558,0,0,1,0-3.116H18.961a1.559,1.559,0,0,1,1.426.928c.62,1,3.447,1.127,3.978-.021a1.554,1.554,0,0,1,1.411-.9l17.135,0a1.558,1.558,0,0,1,0,3.116h-16.3c-1.6,1.891-4.719,2.139-6.878,1.141a4.8,4.8,0,0,1-1.574-1.141H1.535ZM40.917,11.531,26.362,18.443l16.121-.9-1.565-6.01ZM35.044,4.463,25.126,17.017l13.709-8.53L35.044,4.463ZM25.036,0,23.42,15.918,30.4,1.356,25.036,0ZM4.552,11.531l14.556,6.912-16.121-.9,1.565-6.01Zm5.873-7.068,9.918,12.554L6.635,8.487l3.791-4.024ZM20.434,0,22.05,15.917,15.073,1.357,20.434,0Z" transform="translate(0.002)" fill="#656565"/>
                                        </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h4>Lend</h4>
                            <p>You are letting people borrow<br> this article and expecting it<br> to be returned to you.</p>
                        </label>
                    </div>
                    <div class="forms-container">
                    <div class="row no-gutters">
                        <div class="col-md-6">
                        <div class="container-left">
                            <div class="mb-3">
                                <label for="article_name" class="form-label">Name</label>
                                <input type="text" v-model="form.name" class="form-control" id="card_num" placeholder="Enter name">
                            </div>
                            <div class="mb-3">
                                <label for="a_format" class="form-label">Format</label>
                                <select data-name="format_id" class="js-example-basic-single" id="a_format" name="a_format" style="width: 100%;">
                                    <option :value="null">-- Select Format --</option>
                                    <option v-for="(item, index) in articleFormat" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="a_categories" class="form-label">Categories</label>
                                <select data-name="category_id" class="js-example-basic-single" id="a_categories" name="a_categories" style="width: 100%;">
                                    <option :value="null">-- Select Categories --</option>
                                    <!-- <option v-for="(item, index) in articleCategories" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option> -->
                                    <optgroup label="HomeShcooling">
                                        <option value="tex">Textbook</option>
                                        <option value="wor">Workbook</option>
                                        <option value="mag">Magazine</option>
                                    </optgroup>
                                    <optgroup label="Subject">
                                        <option value="bib">Bible</option>
                                        <option value="lang">Language of Arts</option>
                                        <option value="read">Reading</option>
                                        <option value="math">Math</option>
                                        <option value="sci">Science</option>
                                    </optgroup>
                                    <optgroup label="Elective">
                                        <option value="a&m">Arts & Humanities</option>
                                        <option value="musc">oMusic</option>
                                        <option value="forl">Forign Language</option>
                                        <option value="geo">Geography</option>
                                        <option value="char">Character</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="a_author" class="form-label">Author</label>
                                <input type="text" v-model="form.author_id" class="form-control" id="a_author" placeholder="Author" />
                                <!-- <select v-model="form.author_id" class="js-example-basic-single" id="a_author" name="a_author" style="width: 100%;">
                                    <option :value="null">-- Select Author --</option>
                                    <option v-for="(item, index) in articleAuthor" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select> -->
                            </div>
                            <div class="mb-3">
                                <label for="a_publisher" class="form-label">Publisher</label>
                                <input type="text" v-model="form.publisher_id" class="form-control" id="a_publisher" placeholder="Publisher" />
                                <!-- <select v-model="form.publisher_id" class="js-example-basic-single" id="a_publisher" name="a_publisher" style="width: 100%;">
                                    <option :value="null">-- Select Publisher --</option>
                                    <option v-for="(item, index) in articlePublisher" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select> -->
                            </div>
                            <div class="mb-3">
                                <label for="a_copyright" class="form-label">Copyright Year</label>
                                <input type="number" v-model="form.copyright_year_id" class="form-control" id="a_copyright" placeholder="Copyright Year" />
                                <!-- <select v-model="form.copyright_year_id" class="js-example-basic-single" id="a_copyright" name="a_copyright" style="width: 100%;">
                                    <option :value="null">-- Select Copyright Year --</option>
                                    <option v-for="(item, index) in articleCopyrightYear" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select> -->
                            </div>
                            <div class="mb-3">
                                <label for="a_isbn" class="form-label">ISBN</label>
                                <input type="text" v-model="form.isbn_id" class="form-control" id="a_isbn" placeholder="ISBN" />
                                <!-- <select v-model="form.isbn_id" class="js-example-basic-single" id="a_isbn" name="a_isbn" style="width: 100%;">
                                    <option :value="null">-- Select ISBN --</option>
                                    <option v-for="(item, index) in articleIsbn" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select> -->
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6 col-right">
                        <div class="container-right">
                            <div class="mb-3 mt-5">
                                <input v-model="form.is_homeschooling" type="checkbox" class="form-check-input" id="homeschooling">
                                <label for="homeschooling" class="form-label check-l">For Homeschooling</label>
                            </div>
                            <div class="mb-3">
                                <label for="a_grade" class="form-label">Grade</label>
                                <select data-name="homeschool_grade_id" class="js-example-basic-single" id="a_grade" name="a_grade" style="width: 100%;" :disabled="!form.is_homeschooling">
                                    <option :value="null">-- Select Grade --</option>
                                    <option v-for="(item, index) in childrenSchoolGrade" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="a_subject" class="form-label">Subject</label>
                                <select data-name="homeschool_subject_id" class="js-example-basic-single" id="a_subject" name="a_subject" style="width: 100%;" :disabled="!form.is_homeschooling">
                                    <option :value="null">-- Select Subject --</option>
                                    <option v-for="(item, index) in articleHomeschoolSubject" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="a_elective" class="form-label">Elective</label>
                                <select data-name="homeschool_elective_id" class="js-example-basic-single" id="a_elective" name="a_elective" style="width: 100%;" :disabled="!form.is_homeschooling">
                                    <option :value="null">-- Select Elective --</option>
                                    <option v-for="(item, index) in articleHomeschoolEffective" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="a_curriculum" class="form-label">Curriculum</label>
                                <select data-name="homeschool_curriculum_id" class="js-example-basic-single" id="a_curriculum" name="a_curriculum" style="width: 100%;" :disabled="!form.is_homeschooling">
                                    <option :value="null">-- Select Curriculum --</option>
                                    <option v-for="(item, index) in articleHomeschoolCurriculum" :value="item.id" :key="`${index}-${item.name}`">{{ item.name }}</option>
                                </select>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-6">
                        <div class="container-left">
                            <h4 class="title-dvr">Approximate</h4>
                            <div class="mb-3">
                                <label for="a_wei" class="form-label">Weight</label>
                                <input v-model="form.weight" type="text" class="form-control" id="a_wei" placeholder="Enter Weight (lbs)">
                            </div>
                            <div class="mb-3">
                                <label for="a_worth" class="form-label">Worth</label>
                                <input v-model="form.worth" type="number" class="form-control" id="a_worth" placeholder="Enter Worth ($)">
                            </div>
                            <div class="note-container">
                                <p>The worth amount will not show in Article Profile and will only be used for shipping insurance and payment in case of loss.</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <h4 class="title-dvr">Description</h4>
                        <div class="mb-3">
                            <textarea v-model="form.description" class="form-control" placeholder="Enter Descriptions" id="a_des" style="height: 240px"></textarea>
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <h4 class="title-dvr">Acceptance</h4>
                        <div class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" v-model="form.acceptance[0]" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" value="MeetAfter">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Meet after Church Service
                                <span>Available to members within the same church congregation</span>
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" v-model="form.acceptance[1]" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" value="Shipping">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Shipping
                                <span>Paid by the article receiver</span>
                            </label>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" v-model="form.acceptance[2]" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" value="MeetUp">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Meet Up
                                <span>Available to members within 10 miles distance of each other</span>
                            </label>
                        </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-6">
                            <h4 class="title-dvr">Photos</h4>
                        </div>
                        <div class="col-md-6">
                            <a href="javascript:void(0);" @click="$refs.file.click()" class="add-photo-btn"> Add Photos</a>
                            <input type="file" ref="file" @change="onImageSelect" class="file-input">
                        </div>
                        <div class="col-sm-2">
                            <div class="a-img-container" :style="form.photos && form.photos[0] && {
                                backgroundImage: `url('${form.photos[0]}')`,
                                backgroundSize: 'cover'
                            } || {}">
                                <img v-if="photoLoading[0]" :src="require('../assets/preloader.svg')" style='height: 100%; width: 100%; object-fit: contain' />
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="a-img-container" :style="form.photos && form.photos[1] && {
                                backgroundImage: `url('${form.photos[1]}')`,
                                backgroundSize: 'cover'
                            } || {}">
                                <img v-if="photoLoading[1]" :src="require('../assets/preloader.svg')" style='height: 100%; width: 100%; object-fit: contain' />
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="a-img-container" :style="form.photos && form.photos[2] && {
                                backgroundImage: `url('${form.photos[2]}')`,
                                backgroundSize: 'cover'
                            } || {}">
                                <img v-if="photoLoading[2]" :src="require('../assets/preloader.svg')" style='height: 100%; width: 100%; object-fit: contain' />
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="a-img-container" :style="form.photos && form.photos[3] && {
                                backgroundImage: `url('${form.photos[3]}')`,
                                backgroundSize: 'cover'
                            } || {}">
                                <img v-if="photoLoading[3]" :src="require('../assets/preloader.svg')" style='height: 100%; width: 100%; object-fit: contain' />
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="a-img-container" :style="form.photos && form.photos[4] && {
                                backgroundImage: `url('${form.photos[4]}')`,
                                backgroundSize: 'cover'
                            } || {}">
                                <img v-if="photoLoading[4]" :src="require('../assets/preloader.svg')" style='height: 100%; width: 100%; object-fit: contain' />
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="a-img-container" :style="form.photos && form.photos[5] && {
                                backgroundImage: `url('${form.photos[5]}')`,
                                backgroundSize: 'cover'
                            } || {}">
                                <img v-if="photoLoading[5]" :src="require('../assets/preloader.svg')" style='height: 100%; width: 100%; object-fit: contain' />
                            </div>
                        </div>
                    </div>
                    </div>
                    </form>
                </div>
                <div class="d-grid gap-5 d-md-block col-md-6 mx-auto">
                    <button class="btn btn-cancel" type="button" data-bs-toggle="modal" data-bs-target="#modal_meet_up">Cancel</button>
                    <button class="btn btn-continue" type="button" @click="onSubmit">Continue</button>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <!-- <div class="modal fade" id="modal_meet_up" tabindex="-1" aria-labelledby="modal_meet_upLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content modal-meet-up-content">
                <div class="modal-body">
                <div class="head-mod-container">
                    <h3 class="head-modal-title">
                    Change Meetup Details
                    <p><span>Borrower’s Name/Acquiring member</span> your article <span>Article Name here</span>. Please provide meet-up details below:</p>
                    </h3>
                </div>
                <form class="change-pass-form">
                    <div class="mb-3">
                    <label for="datepicker" class="col-form-label">Date</label>
                    <input type="Text" placeholder="MM/DD/YYYY" class="form-control" id="datepicker">
                    </div>
                    <div class="mb-3">
                    <label for="m_time" class="col-form-label">Time</label>
                    <input type="text" placeholder="Enter time" class="form-control" id="m_time">
                    </div>
                    <div class="mb-3">
                    <label for="m_location" class="col-form-label">Location Name</label>
                    <input type="text" placeholder="Enter location name" class="form-control" id="m_location">
                    </div>
                    <div class="mb-3">
                    <label for="m_location_add" class="col-form-label">Location Address</label>
                    <input type="text" placeholder="Enter location address" class="form-control" id="m_location_add">
                    </div>
                </form>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-submit-change" data-bs-toggle="modal">Submit</button>
                    <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Cancel</button>
                </div>
                </div>
            </div>
            </div>
        </div> -->
        <!-- End Modal -->

        <ModalArticleMeetupNotif />
        <ModalArticleMeetup />
        <ModalArticleOverweight />
        <ModalArticlePhotoNotice />
        <ModalArticleSuccess />
    </section>
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { required } from '@vuelidate/validators';
import { computed, defineComponent, getCurrentInstance } from 'vue';
import ModalArticleMeetupNotif from '../components/modals.component/modal-article-meetup-notif.vue';
import ModalArticleMeetup from '../components/modals.component/modal-article-meetup.vue';
import ModalArticleOverweight from '../components/modals.component/modal-article-overweight.vue';
import ModalArticlePhotoNotice from '../components/modals.component/modal-article-photo-notice.vue';
import ModalArticleSuccess from '../components/modals.component/modal-article-success.vue';

export default defineComponent({
    data() {
        return {
            form: {
                name: null,
                type: 'Give',
                format_id: null,
                category_id: null,
                author_id: null,
                publisher_id: null,
                copyright_year_id: null,
                isbn_id: null,
                is_homeschooling: false,
                homeschool_grade_id: null,
                homeschool_subject_id: null,
                homeschool_elective_id: null,
                homeschool_curriculum_id: null,
                acceptance: [null, null, null],
                weight: null,
                worth: null,
                description: null,
                photos: null
            },
            photoLoading: []
        };
    },
    validations () {
        return {
            form: {
                name: { required },
                type: { required },
                format_id: { required },
                category_id: { required },
                author_id: { required },
                publisher_id: { required },
                copyright_year_id: { required },
                isbn_id: { required },
                is_homeschooling: {},
                homeschool_grade_id: { required },
                homeschool_subject_id: { required },
                homeschool_elective_id: { required },
                homeschool_curriculum_id: { required },
                Weight: { required },
                worth: { required },
                description: { required },
                photos: { required }
            }
        };
    },
    setup() {
        const vm = getCurrentInstance();
        const {
            ArticleAcceptance,
            ArticleAuthor,
            ArticleCategories,
            ArticleCopyrightYear,
            ArticleFormat,
            ArticleHomeschoolCurriculum,
            ArticleHomeschoolEffective,
            ArticleHomeschoolSubject,
            ArticleIsbn,
            ArticlePublisher,
            ChildrenSchoolGrade
        }  = vm.appContext.provides.$FeathersVuex.api;

        const articleAcceptance = useFind({ model: ArticleAcceptance, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleAuthor = useFind({ model: ArticleAuthor, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleCategories = useFind({ model: ArticleCategories, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleCopyrightYear = useFind({ model: ArticleCopyrightYear, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleFormat = useFind({ model: ArticleFormat, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleHomeschoolCurriculum = useFind({ model: ArticleHomeschoolCurriculum, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleHomeschoolEffective = useFind({ model: ArticleHomeschoolEffective, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleHomeschoolSubject = useFind({ model: ArticleHomeschoolSubject, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articleIsbn = useFind({ model: ArticleIsbn, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        const articlePublisher = useFind({ model: ArticlePublisher, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });
        
        const childrenSchoolGrade = useFind({ model: ChildrenSchoolGrade, params: computed(() => {
            return { query: { $sort: { id: 1 } } };
        }), fetchParams: computed(() => {
            return { query: { $paginate: false } };
        }), immediate: true });

        return {
            articleAcceptance: articleAcceptance.items,
            articleAuthor: articleAuthor.items,
            articleCategories: articleCategories.items,
            articleCopyrightYear: articleCopyrightYear.items,
            articleFormat: articleFormat.items,
            articleHomeschoolCurriculum: articleHomeschoolCurriculum.items,
            articleHomeschoolEffective: articleHomeschoolEffective.items,
            articleHomeschoolSubject: articleHomeschoolSubject.items,
            articleIsbn: articleIsbn.items,
            articlePublisher: articlePublisher.items,
            childrenSchoolGrade: childrenSchoolGrade.items
        };
    },
    mounted() {
        const self = this;
        window['$']('.js-example-basic-single').select2({
            width:'resolve',
            disabled: false,
            minimumResultsForSearch: Infinity
        }).on("select2:select", function() { 
            const name = window['$'](this).data().name;
            const value = window['$'](this).val();
            self.form[name] = !isNaN(value) ? parseInt(value) : value;
        });
    },
    methods: {
        async onImageSelect(value) {
            const reader = new FileReader()
            reader.readAsDataURL(value.target.files[0]);
            reader.onload = () => {
                const connection = process.env.NODE_ENV === 'development'
                    ? 'http://localhost:3030/'
                    : '/'
                
                // console.log(reader.result);
                // this.$emit('update:modelValue', reader.result);

                if(!this.form.photos) {
                    this.form.photos = [];
                }

                const currentIndex = this.form.photos.length;

                this.form.photos[currentIndex] = reader.result;
                this.photoLoading[currentIndex] = true;

                // this.loading = true;
                // this.$emit('loading', true);
                fetch(`${connection}api/upload`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": window.localStorage.getItem('feathers-jwt')
                    },
                    body: JSON.stringify({
                        uri: reader.result
                    })
                }).then(response => response.json()).then(result => {
                    if(result.code) throw new Error(result.message);
                    // this.$emit('update:modelValue', `https://uploads.quivershare.org/uploads/${result.id}`);
                    this.form.photos[currentIndex] = `https://uploads.quivershare.org/uploads/${result.id}`;
                }).catch(err => {
                    this.$toast(err.message === 'request entity too large' ? 'File is too large and max file size is 5mb.' : err.message, {
                        duration: 3000,
                        styles:  {
                            fontSize: '11pt',
                            backgroundColor: '#ce4f4f'
                        },
                        slot: '<i class="fa fa-exclamation"></i>'
                    });
                }).finally(() => {
                    // this.loading = false;
                    // this.$emit('loading', false);
                    // this.$refs.file.reset();
                    this.photoLoading[currentIndex] = false;
                    console.log('Done!!');
                });

            };
        },
        async onSubmit() {
            console.log(this.form);
        }
    },
    components: {
        ModalArticleMeetupNotif,
        ModalArticleMeetup,
        ModalArticleOverweight,
        ModalArticlePhotoNotice,
        ModalArticleSuccess
    }
})
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
</style>