<template>
    <!-- Modal -->
    <div class="modal fade" id="modal_meet_up_notif" tabindex="-1" aria-labelledby="modal_meet_up_notifLabel" aria-hidden="true">
        <div class="modal-dialog  modal-lg modal-dialog-centered">
            <div class="modal-content modal-meet-up-notif-content">
                <div class="modal-body">
                    <div class="head-mod-container">

                        <div class="msg-icon">
                            <svg id="info-icon" xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114">
                                <path id="Path_79" data-name="Path 79" d="M56.846,0C88.358,0,114,25.642,114,56.846A57.223,57.223,0,0,1,56.846,114C25.642,114,0,88.358,0,56.846A57.093,57.093,0,0,1,56.846,0Zm2.472,21.935A9.3,9.3,0,0,1,68.585,31.2a9.5,9.5,0,0,1-9.268,9.268,9.268,9.268,0,0,1,0-18.537ZM72.6,84.341c-2.78,10.2-29.967,11.122-28.114-2.78,1.236-9.268,3.089-18.228,4.634-27.187.927-5.87-2.78-4.016-6.179-5.252-2.163-.927-2.163-3.707.618-4.325,3.4-.927,15.447-.309,19.463-.309,2.163,0,3.4,1.236,3.707,3.4a30.437,30.437,0,0,1-.618,5.252c-1.545,8.65-3.707,17.919-4.943,26.569,0,1.854.309,5.252,3.089,4.943,2.78,0,4.016-1.236,6.179-2.163C71.675,81.87,72.911,82.8,72.6,84.341Z" fill="#6c782a" fill-rule="evenodd"/>
                            </svg>
                        </div>

                        <h3 class="head-modal-title">
                        Notice
                        </h3>

                        <p class="des-modal">
                        This is a Meet Up Arrangement between : <br>
                        <span>John Doe</span> (<span class="italic">Giver</span>) and <span>Selena Dela Cruz</span> (<span class="italic">Receiver</span>) <br>
                        for the article/s listed below:
                        </p>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-sm-6">
                        <ul class="article-list">
                            <li class="item">
                                365 Read-Aloud Bedtime Bible
                            </li>
                            <li class="item">
                                The Jesus Storybook Bible: Every
                            </li>
                            <li class="item">
                                New Testament Hidden Picture
                            </li>
                        </ul>
                        </div>  
                        <div class="col-sm-6 right">
                        <div class="meet-up-info-contianer">
                            <div class="label">Date <span>May 01 , 2021</span></div>
                            <div class="label">Time <span>02 : 30 PM</span></div>
                            <div class="label">Location Name <span>SB - Puerto Princesa</span></div>
                            <div class="label">Location Address <span>12 , Burong ,  Buena , Palawan</span></div>
                        </div>
                        </div>
                    </div>
                    <div class="btn-grp gap-2">
                        <button type="button" class="btn btn-change">Change Meeting Details</button>
                        <button type="button" class="btn btn-agree">Agree</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
</template>